import axios from "@/axios";
export const ProfileStore = {

    state: {
        refChecks: []
    },

    mutations: {
        SET_REF_CHECKS: (state, payload) => {
            state.refChecks = payload;
        },
    },

    getters: {
        getRefChecks: state => state.refChecks,
    },

    actions: {
        fetchRefChecks: async ({ commit }, payload) => {
            const case_id = payload
            try {
                const { data } = await axios(`case/${case_id}/check`)
                data.data = data.data.map(el => {
                    // adding reference to non reference cards 
                    if (el.check_category == 'Non Reference' || el.check_category == 'integrated') {
                        let obj = el
                        obj.references = [{
                            status_color: el.stage_color || 'blue',
                            stage_id: el.stage_id,
                            status_name: el.stage_name
                        }]

                        return obj 
                    } else return el
                })
                commit('SET_REF_CHECKS', data.data)
            } catch (error) {
                console.log(error, ">>>>Error")
            }
        },

    }

};
