export const LightRayRoutes = [
    {
        name: "light-ray",
        path: "light-ray",
        component: () => import(/* webpackChunkName:"light-ray"  */ "./index.vue"),
        // meta:{
        //     showLightRay:true
        // },
        children: [
            {
                name: "entity-details",
                path: "results/:tool",
                component: () => import(/* webpackChunkName:"results-details" */ "./pages/entity-details"),
                meta: {
                    title: "Entity Details", 
                    showLightRay:true
                }
            },
            {
                name: "search-company-records",
                path: "search-records/:type",
                component: () => import(/* webpackChunkName:"light-ray"  */"./pages/search-records"),
                meta: {
                    title: "Entity Details", 
                    showLightRay:true
                }
            },
        ],
    },
];
