<template>
  <multiselect :options="getOptions" :value="value" @input="setValue" multiple  track-by="id" label="option_label" :disabled="isDisabled" />
</template>

<script>
import multiselect from "vue-multiselect";
import { isJson } from '@shared/components/form-builder/utils';

export default {
  props: {
    // value: {
    //   required: true,
    // },
    context: {
      type: Object,
      required: true,
    },
  },
  components: {
    multiselect,
  },
  methods: {
  
    setValue(val) {
        // console.log('val :>> ', val);
        this.value = JSON.stringify(val) || []

    },
  },
  computed: {
    value: {
        get() {
          return isJson(this.context.model) ? JSON.parse(this.context.model) : this.context.model ||  []
            // return this.context.model ? JSON.parse(this.context.model) : this.context.model ||  []
        },
        set(val) {
            // console.log('val :>> ', val);
            this.context.model = val
        }
    },
    getOptions() {
        let options = this.context.options 
        return (options && options.length) ? options.map(ele => ({...ele, option_label: ele.label, option_value: ele.value})) : []
    },
    isDisabled() {
      return this.context?.attributes?.disabled ?? false
    }
  },
  mounted() {
    // console.log('this.context.model :>> ', this.context.model);
    // console.log('this.context :>> ',  [this.model, this.context.model.from] );
  },
};
</script>

<style>
</style>