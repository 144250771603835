import { formMapper } from "@shared/utils/constants";
import store from "@/store"
import { TENANT_ROLES_MAP } from "@shared/utils/constants"
import { get } from "lodash"



export function getRefreeFormFields(data) {
    const getCorrectToggle = (status) => {
        // if (status) {
        if (status === true) return `correct`;
        else if (status === false) return `incorrect`;
        else return `unselected`;
        // }
    };
    const isJson = (data) => {
        try {
            var testIfJson = JSON.parse(data);
            if (typeof testIfJson == "object") {
                //Json
                return true;
            } else {
                //Not Json
                return false;
            }
        } catch (e) {
            console.log("isJson :>> ", data);
            return false;
        }
    };
    const getFormType = (fld) => {
        return formMapper[fld.field_type] || "text";
    };
    const getIcon = (ele) => {
        if(!ele.is_comment)
        return ele.can_approve ? "referee" : "candidate";
        else
        return 'analyst'
    };
    const getOptions = (fld) => {
        if (fld.field_options && fld.field_options.length) {
            // if (fld.type !== 'checkbox') {
            return fld.field_options.map((el) => ({
                label: el.option_label,
                value: el.option_value,
                id: el.id,
            }));
        }
        // else return fld.field_options.map || []
        else return [];
    };
    const getValue = (form, key) => {
        if (["checkbox", "date-range", "Multi select", "Checkbox"].includes(form.field_type)) {
            let val;
            try {
                // if (isJs)
                val = form[key];
                val = isJson(val) ? JSON.parse(val) : val;
            } catch (error) {
                val = form[key];
                console.log("error :>> getValue", error);
            }
            if ((val && form.field_type === "Checkbox") || form.field_type === "checkbox") {
                // in case of checkbox drop only list of values
                return val.map((el) => (typeof el === "object" ? el?.option_value : el)) || [];
            } else return val;
        } else if (form.field_type === "file" || form.field_type === "File") return form.file_links || [];
        else return form[key];
    };

    // as discussed with @Rohit
    const getCompareValue = (fld) => {
        if (["File", "FILE", "Signature", "Consent", "Label", "label","LABEL"].includes(fld.field_type)) return false;
        else if(["Large text"].includes(fld.field_type) && fld.is_comment)
        return false;
        else return fld.can_approve || false;
    };
     
    const getComment = (ele) =>{
        return ele.field_label === 'Comments' ? "true" : "false"

    }
    //todo uncomment before pushing
    // * test data
    // console.log('data :>> ', data);
    return data.form_values
        .filter((lst) => !["BUTTON"].includes(lst.field_type)).filter(fld => !(fld.field_type === "File" && !fld.can_approve))
        // .sort((a,b) => b.serial - a.serial)
        .map((ele) => ({
            id: ele.id,
            icon: getIcon(ele),
            type: getFormType(ele) || "text",
            value: getValue(ele, "value_str"),
            label: ele.label || ele.field_label,
            placeholder: ele.placeholder,
            compare: getCompareValue(ele),
            correct: getCorrectToggle(ele.ref_input_status),
            correct_value: getValue(ele, "ref_input_value") || "",
            options: getOptions(ele),
            is_conditional: ele.is_conditional || false,
            condition: ele.condition || null,
            api_data: ele,
            is_comment:getComment(ele),
            ref_field: ele?.is_comment ? false : true,
            mandatory: ele.mandatory,
            case_reference_id:ele.case_reference_id,
            can_approve: ele?.can_approve,
            meta: ele?.meta
        }));
}

export function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
}

export function getUniqueList(list) {
    return list.filter(onlyUnique);
}

export function checkColumnConfig(column) {
    if (column?.filterOptions?.columnConfig) {
        return column.filterOptions.columnConfig;
    } else {
        return { label: "name", trackBy: "id", };
    }
}

export function prePopulatedDropdownItems(columns, rows) {
    return columns.map((col) => {
        const filtered = col.filterOptions?.enabled;
        const custom_filters = col.filterOptions && col.filterOptions.customDropdownItems && col.filterOptions.customDropdownItems.length > 0;
        if (custom_filters) {
            return {
                ...col,
                filterOptions: {
                    ...col.filterOptions,
                    filterDropdownItems: col.filterOptions.customDropdownItems,
                },
            };
        } else {
            return {
                ...col,
                filterOptions: {
                    ...col.filterOptions,
                    filterDropdownItems: filtered && removeDuplicates(rows
                        .filter((row) => row[col.field])
                        .map((row) => ({ id: row.id, name: row[col.field] }))
                        .filter(onlyUnique)),
                },
            };

        }
    });
}

export function checkPermission(permission) {
  
//    console.log('permission :>> ', permission);
    return store.getters.getUserPermissions?.includes(  get(TENANT_ROLES_MAP, permission, "")) 
}

export function getBaseUrlDelviumBackend(){

    
    if(process.env.VUE_APP_IS_LOCAL){
        // Absolute url to enable local development
        return `${process.env.VUE_APP_API_BASE_URL}/api/v1`
    }else{
        // Relative url for deployed apps
        return `/api/v1`
    }

}

//this function for  truncate tooltip content
export function fetchLabel(data){
    const sanitizedContent = data?.replace(/<[^>]+>/g, (match) => {
        return match?.replace(/style="[^"]*"/g, '');
    });
    return sanitizedContent && sanitizedContent?.length>500 ? `${sanitizedContent.substring(0,497)}...`:sanitizedContent
}

export function getBaseUrlDelviumLightray(){
    if(process.env.VUE_APP_IS_LOCAL){
        // Absolute url to enable lightray development
        return `${process.env.VUE_APP_BACKEND_API_BASE_URL}/api/v1`
    }else{
        // Relative url for deployed apps
        return `/api/v1`
    }
}

function removeDuplicates(inputArray){
    const uniqueArray = []
    const obj = {}
    for(let val of inputArray){
       if (val?.name && !obj[val.name]){ 
            uniqueArray.push(val)
            obj[val.name]=val.name
       }
    }
    return uniqueArray;
}