
export const SET_FIELDS_TYPES = "fields-admin/SET_FIELDS_TYPES";
export const FETCH_FIELDS = "fields-admin/FETCH_FIELDS";
import axios from "@/axios";
export const FieldsAminStore = {
	state: {
		fields_types: []
	},
	mutations: {
		[SET_FIELDS_TYPES](state, { fields_types }) {
			state.fields_types = fields_types;
		}
	},
	getters: {
		fieldsType: state => {
			return state.fields_types;
		}
	},
	actions: {
		[FETCH_FIELDS]: async ({ commit }) => {
			commit(SET_FIELDS_TYPES, { fields_types: [] });
			let result = await axios.get('/fields/types')
			let new_result = result.data.field_types
			let fieldIcons = {
				"Input": "/icons/svg/input-text.svg",
				"Large text": '/icons/svg/input-text.svg',
				"Number": "/icons/svg/input-numeric.svg",
				"Radio": "/icons/svg/circle-dot.svg",
				"Radio group": "/icons/svg/circle-dot.svg",
				"Single select": "/icons/svg/circle-dot.svg",
				"Multi select": "/icons/svg/circle-dot.svg",
				"Date range": "/icons/svg/calendar-week.svg",
				"File": "/icons/svg/folder-arrow-up.svg",
				"Checkbox": "/icons/svg/square-check.svg",
				"Checkbox group": "/icons/svg/square-check.svg",
				"custom_field": "/icons/svg/input-text.svg",
			}
			let fields_list = new_result.map(el => {
				return { ...el, icon: fieldIcons[el.name] || "/icons/svg/circle-dot.svg" }
			})

			// let fieldList = {

			// }
			commit(SET_FIELDS_TYPES, { fields_types: fields_list });

		}
	}
};

