import axios from "@/axios";
export const PackageStore = {

    state: {
        packageData: null,
        roles: []
    },

    mutations: {
        SET_PACKAGE(state, packageData) {
            state.packageData = packageData;
        },
        SET_ROLES(state, rolesData) {
            state.roles = rolesData
        }
    },

    getters: {
        getPackage: state => state.packageData,
        getRoles: state => state.roles,
    },

    actions: {
        fetchPackageData: async ({ commit }, payload) => {
            if (payload) {
                let package_id = payload
                const url = `/screening-package/${package_id}`;
                try {
                    let { data } = await axios.get(url);
                    commit('SET_PACKAGE', data)
                } catch (error) {
                    console.log(error, ">>>error fetch package data")
                }
            }
        },
        fetchRoles: async ({ commit }) => {
            const url = `/tenant-check/section/field/valid-roles/`;
            try {
                let { data } = await axios.get(url);
                commit('SET_ROLES', data.roles)
            } catch (error) {
                console.log(error, '>>>fetch roles data')
            }
        },

    }

};
