import Vue from 'vue'
import VueFormulate from '@braid/vue-formulate'
// multiselct plugin 
import FormulateVSelectPlugin from '@cone2875/vue-formulate-select'
import 'vue-select/dist/vue-select.css';
import required from "./formulate-library/required"
// custom fields
import RichTextEditor from './formulate-library/rich-text-editor'
import PhoneField from "../components/phone-field/"
import DateRange from "./formulate-library/date-range"
import Multiselect from "./formulate-library/multi-select"
import Consent from "./formulate-library/consent"
import Signature from "./formulate-library/signature"
import File from "./formulate-library/file"
import Label from "./formulate-library/label"
import Pills from "./formulate-library/pills"
// Register our slot component globally
Vue.component('required', required)

Vue.use(VueFormulate, {
    classes: {
        // outer: 'mytheme-wrapper w-full max-w-full mb-2',
        // wrapper: "form-control select-none  flex w-full",
        // label: "label text-base",
        // inputHasErrors: "border-red-600",
        // // input: "input focus:border-primary w-ull max-w-full w-full  input-bordered",
        // help: "label label-alt",
        // errors: "text-red-700 py-1 text-sm"
    },
    // Define our custom slot component(s)
    slotComponents: {
        label: 'required'
    },
    // Define any props we want to pass to our slot component
    slotProps: {
        label: ['showRequired', 'showLabel']
    },
    plugins: [FormulateVSelectPlugin],
    library: {
        'rich-text': {
            classification: 'rich-text',
            component: RichTextEditor,
        },
        'phone-field':{
            classification: 'phone-field',
            component: PhoneField,
        },
        'date-range': {
            component: DateRange,
            classification: 'date-range',
        },
        "multi-select": {
          component: Multiselect,
          classification  :   "multi-select"
        },
        "signature": {
          component: Signature,
          classification  :   "signature"
        },
        "consent" : {
            component: Consent,
            classification: "consent"
        },
        "file-list": {
            component: File,
            classification: "file-list"
        },
        "pills":{
            component: Pills,
            classification: "pills"
        },
        "label": {
            component: Label,
            classification: "label"
        }
    },
})