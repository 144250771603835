const formMapper = {
    "Input": 'text',
    "Email": 'email',
    "Select": 'select',
    "Radio": "radio",
    "Date": "date",
    "Checkbox": "checkbox",
    "Date Range": "date-range",
    "Single select": "select",
    "Large text": "textarea",
    "Multi select": "multi-select",
    "Multiselect": "multi-select",
    "Consent": "consent",
    "Canvas": "signature",
    "signature": "signature",
    "File": "file-list",
    "file": "file-list",
    "Label": "label",
    "label": "label",
    "LABEL": "label",
}

const TENANT_ROLES_MAP = {
    field: {
        read: "READ_FIELD",
        create: "CREATE_FIELD",
        delete: "DELETE_FIELD",
        edit: "EDIT_FIELD",
    },
    block: {
        read: "READ_BLOCK",
        create: "CREATE_BLOCK",
        delete: "DELETE_BLOCK",
        edit: "EDIT_BLOCK",
    },
    check: {
        read: "READ_CHECK",
        create: "CREATE_CHECK",
        delete: "DELETE_CHECK",
        edit: "EDIT_CHECK",
    },
    package:{
        read: "READ_PACKAGE",
        create: "CREATE_PACKAGE", 
        delete: "DELETE_PACKAGE",
        edit: "EDIT_PACKAGE",
    },
    form:{
        read: "READ_ADDITIONAL_FORM",
        create: "CREATE_ADDITIONAL_FORM",
        delete: "DELETE_ADDITIONAL_FORM",
        edit: "EDIT_ADDITIONAL_FORM",
    },
    // CASE SPECIFIC ROLES
    case:{
        read: 'READ_CASE',
        create: 'CREATE_CASE',
        delete: 'DELETE_CASE',
        allocate: 'CASE_ALLOCATION',
        // profile action
        activity_history: 'ACTIVITY_HISTORY',
        actions_internal: 'CASE_ACTIONS_INTERNAL',
        actions_external: 'CASE_ACTIONS_EXTERNAL_COMMUNICATION',
        // tabs
        read_input: "READ_INPUT",
        read_write_input: "READ_WRITE_INPUT",
        read_check_screen: "R_CHECK_SPECIFIC_SCREEN",
        read_write_check_screen: "RW_CHECK_SPECIFIC_SCREEN",
        read_output: "READ_OUTPUT",
        write_output: "WRITE_OUTPUT",
        case_allocation: 'EDIT_CASE_ALLOCATION',
        // file folder
        internal_folder: 'RW_INTERNAL_FOLDER',
        // read_write_internal_folder: 'RW_INTERNAL_FOLDER'
        edit: 'EDIT_CASE_ALLOCATION',
        status_update: "UPDATE_CASE_STATUS",
    },
  
    // case_status: {
    //     update: "UPDATE_CASE_STATUS"9
    // },
    // case_allocation: {
    //     edit: 'EDIT_CASE_ALLOCATION'
    // },
    
    client:{
        read: "READ_CLIENTS_LIST",
        create: "CREATE_CLIENT", 
        delete: "DELETE_CLIENT",
        edit: "EDIT_CLIENT",
    },
    dashboard:{
        read: 'READ_DASHBOARD'
    },
    users:{
        read: "READ_USERS",
        create: "CREATE_USER", 
        delete: "DELETE_USER",
        edit: "EDIT_USER",
    },
    groups:{
        read: "READ_GROUPS",
        create: "CREATE_GROUP", 
        delete: "DELETE_GROUP",
        edit: "EDIT_GROUP",
    },
    role:{
        read: "READ_ROLE",
        create: "ADD_ROLE", 
        delete: "DELETE_ROLE",
        edit: "EDIT_ROLE",
        add: "ADD_ROLE"
    },
    email:{
        read: "READ_EMAIL_TEMPLATES",
        create: "CREATE_EMAIL_TEMPLATE", 
        delete: "DELETE_EMAIL_TEMPLATE",
        edit: "EDIT_EMAIL_TEMPLATE",
    },
    sequence:{
        read: "READ_EMAIL_SEQUENCES",
        create: "CREATE_EMAIL_SEQUENCE", 
        delete: "DELETE_EMAIL_SEQUENCE",
        edit: "EDIT_EMAIL_SEQUENCE",
        deafult: "READ_TENANT_DEFAULT_TEMPLATE_SEQUENCES"
    },
    theme:{
        edit:'EDIT_THEME_AND_LOGO'
    },
    customDomain: {
        read: 'READ_CUSTOM_DOMAIN_CUSTOM_EMAIL',
        edit: 'EDIT_CUSTOM_DOMAIN_CUSTOM_EMAIL'
    },
    defaultEmailSequence: {
        read: 'READ_TENANT_DEFAULT_TEMPLATE_SEQUENCES',
        edit: 'EDIT_TENANT_DEFAULT_TEMPLATE_SEQUENCES'
    },   
    tax:{
        read: "READ_CREATE_TAX",
        activate : "ACTIVATE_DEACTIVATE_TAX",
        allocate: "ALLOCATE_TAX",
        createRead: "READ_CREATE_TAX",
        edit: "EDIT_TAX",
        create: "CREATE_TAX"
    },
    invoice: {
        read: "READ_PURCHASE_INVOICE",
        print:"EMAIL_PRINT_PURCHASE_INVOICE",
        create: "CREATE_PURCHASE_INVOICE"
    },
    history:{
        activity: "ACTIVITY_HISTORY"
    }, 
    source:{
        read: "READ_SOURCE_USAGE"
    },
    action:{
        internal: "CASE_ACTIONS_INTERNAL"
    },
    inventory:{
        read: "READ_INVENTORY"
    },
    input:{
        read: "READ_WRITE_INPUT"
    },
    creditsAllocation:{
        read: "READ_CREDIT_ALLOCATION",
    },
    screen:{
        read: "RW_CHECK_SPECIFIC_SCREEN",
    },
    creditsRequest:{
        read: "READ_CREDIT_REQUEST",
    },
    salesInvoice: {
        read: "READ_CREATE_SALES_INVOICE",
        create: "CREATE_SALES_INVOICE"
    },
    purchaseOrder:{
        create: "CREATE_PURCHASE_ORDER",
        approve: "APPROVE_REJECT_PURCHASE_ORDER",
        read: "READ_CREATE_PURCHASE_ORDER"
    }, 
    salesOrder:{
        read: "READ_CREATE_SALES_ORDER",
        approve: "APPROVE_REJECT_SALES_ORDER",
        create: "CREATE_SALES_ORDER",
    },
    creditPack:{
        read: "READ_CREDIT_PACK",
        allocate: "ALLOCATE_CREDIT_PACK"
    },
    license: {
        read: "READ_LICENSE",
        delete: "DELETE_LICENSE",
        edit: "EDIT_LICENSE",
        create: "CREATE_LICENSE"
    },
    salesPayment: {
        approve: "APPROVE_SALES_PAYMENT",
    },
    domain:
    {
        read: "READ_CUSTOM_DOMAIN_CUSTOM_EMAIL",
        edit: "EDIT_CUSTOM_DOMAIN_CUSTOM_EMAIL"
    }
    
    
    // "read_field":
    // create_field: "CREATE_FIELD",

    //

    // read_block: "READ_BLOCK",
    // create_block: "CREATE_BLOCK",
    // edit_block: "EDIT_BLOCK",
    // delete_block: "DELETE_BLOCK",
    // read_check: "READ_CHECK",
    // "CREATE_CHECK",
    // "EDIT_CHECK",
    // "DELETE_CHECK"
}

const allMonths = [
    {
        key: 1,
        small_label: 'Jan',
        large_label: 'Januray'
    },
    {
        key: 2,
        small_label: 'feb',
        large_label: 'February'
    },
    {
        key: 3,
        small_label: 'Mar',
        large_label: 'March'
    },
    {
        key: 4,
        small_label: 'Apr',
        large_label: 'April'
    },
    {
        key: 5,
        small_label: 'May',
        large_label: 'May'
    },
    {
        key: 6,
        small_label: 'Jun',
        large_label: 'June'
    },
    {
        key: 7,
        small_label: 'July',
        large_label: 'July'
    },
    {
        key: 8,
        small_label: 'Aug',
        large_label: 'August'
    },
    {
        key: 9,
        small_label: 'Sep',
        large_label: 'September '
    },
    {
        key: 10,
        small_label: 'Oct',
        large_label: 'October'
    },
    {
        key: 11,
        small_label: 'Nov',
        large_label: 'November'
    },
    {
        key: 12,
        small_label: 'Dec',
        large_label: 'December'
    }
]

const DEFAULT_DOMAIN_NAME = "Delvium";
const DEFAULT_FAVICON = `${process.env.VUE_APP_API_BASE_URL}/favicon.ico`;

export { formMapper, TENANT_ROLES_MAP, allMonths, DEFAULT_DOMAIN_NAME, DEFAULT_FAVICON };
