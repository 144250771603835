import axios from "@/axios";



export const CaseStore = {

    state: {
        caseData: null,
        caseStatusList: [],
    },

    mutations: {
        SET_CASE_DETAILS(state, caseData ) {
            state.caseData = caseData;
        },
        SET_CASE_STATUS_LISTS(state, caselist){
            state.caseStatusList = caselist
        }
    },

    getters: {
        getCaseData: state => state.caseData,
        getCaseStatusList: state => state.caseStatusList,
    },

    actions: {
        fetchCaseData: async ({ commit }, payload) => {
            if(payload){
                let case_id = payload
                const url = `/case/${case_id}`;
                try {
                    let { data } = await axios.get(url);
                    commit('SET_CASE_DETAILS', data.data)
                } catch (error) {
                    console.log("error>>>", error)

                    // Vue.$toast.warning("case not found!");
                }
            }
        },
        fetchCaseStatusLists: async({commit}) => {
            const url = `/case/status`;
            try {
                let { data } = await axios.get(url);
                commit('SET_CASE_STATUS_LISTS', data.data?.case_status)
            } catch (error) {
                console.log("error>>>", error)
                // Vue.$toast.warning("case not found!");
            }
        }
    }

};