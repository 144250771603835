import VueCookies from "vue-cookies";
import { getSearchedRecords, getSearchedDomainRecords } from "./services";

export let LightRayStore = {
    state: {
        entitiesData: null,
        caseId: null,
        apiHeaders: {
            "x-case-id": null,
            "x-status": "RUNNING",
            "x-visited": true,
            "x-monitoring": false,
            "x-tool-name": "adverse-media-tool"
        },
        companyAllData: [],
        entitiesRelationsAttributesData: { attributes: [], entities: [], relations: [] }
    },
    mutations: {
        SET_ENTITIES_DATA(state, payload) {
            state.entitiesData = payload
        },
        SET_NEOTAS_CASE_ID(state) {
            state.caseId = VueCookies.get("neotas_case");
            state.apiHeaders["x-case-id"] = VueCookies.get("neotas_case")
        },
        SET_COMPANY_DATA(state, payload) {
            state.companyAllData = payload
        },
        SET_ENTITIES_RELATIONS_ATTRIBUTES_DATA(state, payload) {
            state.entitiesRelationsAttributesData = payload;
        },
    },
    getters: {
        getEntitiesRelationsAttributesData: (state) => state.entitiesRelationsAttributesData,
        getEntitiesData: (state) => state.entitiesData,
        getCaseId: (state) => state.caseId,
        getApiHeaders: (state) => state.apiHeaders,
        getCompanyAllData: (state) => state.companyAllData,
    },
    actions: {
        setNeotasCaseId({ commit }) {
            commit("SET_NEOTAS_CASE_ID");
        },
        async setSearchData({ commit }, payload) {
            try {
                const [companySearchData, companyDomainData] = await Promise.all([
                    getSearchedRecords(payload.companySearchPayload),
                    getSearchedDomainRecords(payload.domainPayload)
                ]);
                let allCompanyData = { companySearchData: companySearchData.data.data, companyDomainData: companyDomainData.data.data }
                commit("SET_COMPANY_DATA", allCompanyData);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
    },
};
